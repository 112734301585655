import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-eula',
  templateUrl: './page-eula.component.html',
  styleUrls: ['./page-eula.component.css']
})
export class PageEulaComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  constructor() { }

  ngOnInit(): void {
  }

}