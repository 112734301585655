<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="24" alt="">
        <img src="assets/images/logo-light.png" class="logo-dark-mode" height="24" alt="">
      </a>

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="24" alt="">
      </a>
    </div>
    <!--end login button-->

    <div id="navigation" *ngIf="Menuoption == 'center'">
      <!-- ACTIVE Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li><a routerLink="/page-contact-one" class="nav-link-ref">Support</a></li>
        <li><a routerLink="/page-terms" class="nav-link-ref" >Terms of Services</a></li>
        <li><a routerLink="/page-privacy" class="nav-link-ref" >Privacy Policy</a></li>
        <li><a routerLink="/page-eula" class="nav-link-ref" >EULA</a></li>
      </ul>
      <!--end ACTIVE Navigation menu-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->