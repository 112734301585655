<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/software/bg.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center position-relative mt-5" style="z-index: 1;">
      <div class="col-lg-6 col-md-12">
        <div class="title-heading mt-4 text-lg-left">
          <h1 class="heading mb-3 title-dark text-white">Create Lasting Memorials with Stone Simple</h1>
          <p class="para-desc text-white-50">Easily create heartfelt memorials with Stone Simple, designed for Morticians, Funeral Home staff, and Monument Shop owners.</p>
<!--           <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-file-download"></i> Download Now</a>
          </div> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-12 mt-4 pt-2">
        <div class="position-relative">
          <div class="software_hero">
            <img src="assets/images/software/software.png" class="img-fluid d-block" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Section Start -->
<section class="section pt-md-4 pt-5">
  <!-- Start Features -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Key Features:</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center mt-5"></app-services>
    
  </div>
  <!--end container-->
  <!-- End Features -->

  <div class="container mt-100 mt-60">
    <div class="card rounded bg-light shadow border-0">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <div class="text-sm-center">
              <h5 class="mb-0">Start building beautiful memorials for your customers now.</h5>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row -->
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/software/mobile01.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Seamless App Experience: Enhancing Your Journey</h4>
          <p class="text-muted">Discover a user-centric App interface that redefines usability, ensuring a smooth and enjoyable interaction from start to finish.</p>
          <ul class="list-unstyled mb-0 text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Intuitive Navigation: Effortlessly explore features through a thoughtfully designed interface, reducing learning curves and frustration.</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Effortless Order Management: Streamline order tracking and management, simplifying your tasks and saving you time.</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Lightning-fast Performance: Enjoy swift loading times and responsiveness, allowing you to accomplish tasks with efficiency and ease.</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Extensive Emblem Library: Access a vast collection of emblems, enhancing your designs with a wide range of options.</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Custom Porcelain Creations: Create personalized porcelain items, adding a touch of uniqueness and elegance to your memorials.</li>                  
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- section End -->


<!--  -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Empower Memories, Honor Lives</h4>
          <p class="text-muted para-desc mx-auto mb-0">
Download Stone Simple now and embark on a journey to create heartfelt memorials that celebrate lives lived beautifully. Compassionate design, meticulous customization – it all starts here.            
          </p>
          <div class="mt-4 pt-2">
APP STORE IMAGE AND LINK HERE WILL ADD ONCE APPROVED
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--  -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->