import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageContactOneComponent } from './core/components/page-contact-one/page-contact-one.component';
import { IndexSoftwareComponent } from './core/components/index-software/index-software.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageEulaComponent } from './core/components/page-eula/page-eula.component';
import { ComponentsComponent } from './core/components/components/components.component';
import { DocumentationComponent } from './core/components/documentation/documentation.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { WidgetComponent } from './core/components/widget/widget.component';

// import { AuthCoverLoginComponent } from './auth/auth-cover-login/auth-cover-login.component';
// import { AuthCoverRePasswordComponent } from './auth/auth-cover-re-password/auth-cover-re-password.component';
// import { AuthCoverSignupComponent } from './auth/auth-cover-signup/auth-cover-signup.component';
// import { AuthLoginThreeComponent } from './auth/auth-login-three/auth-login-three.component';
// import { AuthLoginComponent } from './auth/auth-login/auth-login.component';
// import { AuthRePasswordThreeComponent } from './auth/auth-re-password-three/auth-re-password-three.component';
// import { AuthRePasswordComponent } from './auth/auth-re-password/auth-re-password.component';
// import { AuthSignupThreeComponent } from './auth/auth-signup-three/auth-signup-three.component';
// import { AuthSignupComponent } from './auth/auth-signup/auth-signup.component';
// import { PageAboutusTwoComponent } from './core/components/page-aboutus-two/page-aboutus-two.component';
// import { PageAboutusComponent } from './core/components/page-aboutus/page-aboutus.component';
// import { PageCaseDetailComponent } from './core/components/page-case-detail/page-case-detail.component';
// import { PageCasesComponent } from './core/components/page-cases/page-cases.component';
// import { PageComingsoonComponent } from './core/components/page-comingsoon/page-comingsoon.component';
// import { PageComingsoon2Component } from './core/components/page-comingsoon2/page-comingsoon2.component';
// import { PageContactDetailComponent } from './core/components/page-contact-detail/page-contact-detail.component';
// import { PageContactThreeComponent } from './core/components/page-contact-three/page-contact-three.component';
// import { PageContactTwoComponent } from './core/components/page-contact-two/page-contact-two.component';
// import { PageErrorComponent } from './core/components/page-error/page-error.component';
// import { PageThankyouComponent } from './core/components/page-thankyou/page-thankyou.component';
// import { PageHistoryComponent } from './core/components/page-history/page-history.component';
// import { PageInvoiceComponent } from './core/components/page-invoice/page-invoice.component';
// import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
// import { PagePricingComponent } from './core/components/page-pricing/page-pricing.component';
// import { PageServicesComponent } from './core/components/page-services/page-services.component';
// import { PageTeamComponent } from './core/components/page-team/page-team.component';
// import { PageWorkClassicComponent } from './core/components/page-work-classic/page-work-classic.component';
// import { PageWorkDetailComponent } from './core/components/page-work-detail/page-work-detail.component';
// import { PageWorkGridComponent } from './core/components/page-work-grid/page-work-grid.component';
// import { PageWorkMasonryComponent } from './core/components/page-work-masonry/page-work-masonry.component';
// import { PageWorkModernComponent } from './core/components/page-work-modern/page-work-modern.component';
// import { EmailAlertComponent } from './email/email-alert/email-alert.component';
// import { EmailConfirmationComponent } from './email/email-confirmation/email-confirmation.component';
// import { EmailInvoiceComponent } from './email/email-invoice/email-invoice.component';
// import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
// import { SwitcherComponent } from './shared/switcher/switcher.component';
// import { combineLatest } from 'rxjs/operators';
// import { NftExploreComponent } from './core/components/nft-explore/nft-explore.component';
// import { NftCreatorsComponent } from './core/components/nft-creators/nft-creators.component';
// import { AuthBsLoginComponent } from './auth/auth-bs-login/auth-bs-login.component';
// import { AuthLoginBgVideoComponent } from './auth/auth-login-bg-video/auth-login-bg-video.component';
// import { AuthBsSignupComponent } from './auth/auth-bs-signup/auth-bs-signup.component';
// import { AuthSignupBgVideoComponent } from './auth/auth-signup-bg-video/auth-signup-bg-video.component';
// import { PageFooterComponent } from './core/components/page-footer/page-footer.component';
// import { UiButtonComponent } from './core/components/ui-button/ui-button.component';
// import { UiAlertComponent } from './core/components/ui-alert/ui-alert.component';
// import { UiTypographyComponent } from './core/components/ui-typography/ui-typography.component';
// import { UiDropdownComponent } from './core/components/ui-dropdown/ui-dropdown.component';
// import { UiBackgroundComponent } from './core/components/ui-background/ui-background.component';
// import { UiTextComponent } from './core/components/ui-text/ui-text.component';
// import { UiBadgesComponent } from './core/components/ui-badges/ui-badges.component';
// import { UiAccordionComponent } from './core/components/ui-accordion/ui-accordion.component';
// import { UiCardComponent } from './core/components/ui-card/ui-card.component';
// import { UiShadowComponent } from './core/components/ui-shadow/ui-shadow.component';
// import { UiBorderComponent } from './core/components/ui-border/ui-border.component';
// import { UiCarouselComponent } from './core/components/ui-carousel/ui-carousel.component';
// import { UiBreadcrumbComponent } from './core/components/ui-breadcrumb/ui-breadcrumb.component';
// import { UiPaginationComponent } from './core/components/ui-pagination/ui-pagination.component';
// import { UiAvatarComponent } from './core/components/ui-avatar/ui-avatar.component';
// import { UiNavTabsComponent } from './core/components/ui-nav-tabs/ui-nav-tabs.component';
// import { UiModalsComponent } from './core/components/ui-modals/ui-modals.component';
// import { UiTablesComponent } from './core/components/ui-tables/ui-tables.component';
// import { UiIconsComponent } from './core/components/ui-icons/ui-icons.component';
// import { UiProgressbarComponent } from './core/components/ui-progressbar/ui-progressbar.component';
// import { UiLightboxComponent } from './core/components/ui-lightbox/ui-lightbox.component';
// import { UiTooltipPopoverComponent } from './core/components/ui-tooltip-popover/ui-tooltip-popover.component';
// import { UiFormComponent } from './core/components/ui-form/ui-form.component';
// import { AuthBsResetComponent } from './auth/auth-bs-reset/auth-bs-reset.component';
// import { AuthResetPasswordBgVideoComponent } from './auth/auth-reset-password-bg-video/auth-reset-password-bg-video.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexSoftwareComponent },
      { path: 'index', component: IndexSoftwareComponent }, 
      { path: 'index-software', component: IndexSoftwareComponent },
      { path: 'components', component: ComponentsComponent },
      { path: 'documentation', component: DocumentationComponent },
      { path: 'page-contact-one', component: PageContactOneComponent },
	  { path: 'page-terms', component: PageTermsComponent },
      { path: 'page-privacy', component: PagePrivacyComponent },
      { path: 'page-eula', component: PageEulaComponent },

      // { path: 'page-aboutus', component: PageAboutusComponent },
      // { path: 'page-aboutus-two', component: PageAboutusTwoComponent },
      // { path: 'page-case-detail', component: PageCaseDetailComponent },
      // { path: 'page-cases', component: PageCasesComponent },
      // { path: 'page-contact-detail', component: PageContactDetailComponent },
      // { path: 'page-contact-three', component: PageContactThreeComponent },
      // { path: 'page-contact-two', component: PageContactTwoComponent },
      // { path: 'page-history', component: PageHistoryComponent },
      // { path: 'page-invoice', component: PageInvoiceComponent },
      // { path: 'page-pricing', component: PagePricingComponent },
      // { path: 'page-services', component: PageServicesComponent },
      // { path: 'page-team', component: PageTeamComponent },
      // { path: 'page-work-classic', component: PageWorkClassicComponent },
      // { path: 'page-work-detail', component: PageWorkDetailComponent },
      // { path: 'page-work-grid', component: PageWorkGridComponent },
      // { path: 'page-work-masonry', component: PageWorkMasonryComponent },
      // { path: 'page-work-modern', component: PageWorkModernComponent },
      // { path: 'widget', component: WidgetComponent },
      // { path: '#', component: SwitcherComponent },
      // { path: 'nft-explore', component:  NftExploreComponent},
      // { path: 'nft-creators', component:  NftCreatorsComponent},
      // { path: 'page-footer', component:  PageFooterComponent},
      // { path: 'ui-button', component:  UiButtonComponent},
      // { path: 'ui-alert', component:  UiAlertComponent},
      // { path: 'ui-typography', component:  UiTypographyComponent},
      // { path: 'ui-dropdown', component:  UiDropdownComponent},
      // { path: 'ui-background', component:  UiBackgroundComponent},
      // { path: 'ui-text', component:  UiTextComponent},
      // { path: 'ui-badges', component:  UiBadgesComponent},
      // { path: 'ui-accordion', component:  UiAccordionComponent},
      // { path: 'ui-card', component:  UiCardComponent},
      // { path: 'ui-shadow', component:  UiShadowComponent},
      // { path: 'ui-border', component:  UiBorderComponent},
      // { path: 'ui-carousel', component:  UiCarouselComponent},
      // { path: 'ui-breadcrumb', component:  UiBreadcrumbComponent},
      // { path: 'ui-pagination', component:  UiPaginationComponent},
      // { path: 'ui-avatar', component:  UiAvatarComponent},
      // { path: 'ui-nav-tabs', component:  UiNavTabsComponent},
      // { path: 'ui-modals', component:  UiModalsComponent},
      // { path: 'ui-tables', component:  UiTablesComponent},
      // { path: 'ui-icons', component:  UiIconsComponent},
      // { path: 'ui-progressbar', component:  UiProgressbarComponent},
      // { path: 'ui-lightbox', component:  UiLightboxComponent},
      // { path: 'ui-tooltip-popover', component:  UiTooltipPopoverComponent},
      // { path: 'ui-form', component:  UiFormComponent},
    ]
  },

  // { path: 'auth-login', component: AuthLoginComponent },
  // { path: 'auth-bs-login', component: AuthBsLoginComponent },
  // { path: 'auth-login-bg-video', component: AuthLoginBgVideoComponent },
  // { path: 'auth-cover-login', component: AuthCoverLoginComponent },
  // { path: 'auth-cover-re-password', component: AuthCoverRePasswordComponent },
  // { path: 'auth-cover-signup', component: AuthCoverSignupComponent },
  // { path: 'auth-login-three', component: AuthLoginThreeComponent },
  // { path: 'auth-re-password', component: AuthRePasswordComponent },
  // { path: 'auth-re-password-three', component: AuthRePasswordThreeComponent },
  // { path: 'auth-bs-reset', component: AuthBsResetComponent },
  // { path: 'auth-reset-password-bg-video', component: AuthResetPasswordBgVideoComponent },
  // { path: 'auth-signup', component: AuthSignupComponent },
  // { path: 'auth-bs-signup', component: AuthBsSignupComponent },
  // { path: 'auth-signup-bg-video', component: AuthSignupBgVideoComponent },
  // { path: 'auth-signup-three', component: AuthSignupThreeComponent },
  // { path: 'page-comingsoon', component: PageComingsoonComponent },
  // { path: 'page-comingsoon2', component: PageComingsoon2Component },
  // { path: 'page-error', component: PageErrorComponent },
  // { path: 'page-thankyou', component: PageThankyouComponent },
  // { path: 'page-maintenance', component: PageMaintenanceComponent },
  // { path: 'email-alert', component: EmailAlertComponent },
  // { path: 'email-confirmation', component: EmailConfirmationComponent },
  // { path: 'email-invoice', component: EmailInvoiceComponent },
  // { path: 'email-password-reset', component: EmailPasswordResetComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
