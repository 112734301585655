<div *ngIf="!hideFooter">
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-py-60">
                            <div class="row">
                                <div class="col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <a href="javascript:void(0)" class="logo-footer">
                                        <img src="assets/images/logo-light.png" height="24" alt="">
                                    </a>
                                    <p class="mt-4">
                                        <strong>Create Lasting Memorials with Stone Simple</strong><br>
                                        Crafting meaningful memorials that honor cherished lives has never been easier. Stone Simple, tailored to Morticians, Funeral Home employees, and Monument Shop owners, offers a seamless, heartfelt monument creation experience.
                                    </p>
                                    <a routerLink="/" class="nav-link-ref">Home</a> | <a routerLink="/page-contact-one" class="nav-link-ref">Support</a> | <a routerLink="/page-terms" class="nav-link-ref" >Terms of Services</a> | <a routerLink="/page-privacy" class="nav-link-ref" >Privacy Policy</a> | <a routerLink="/page-eula" class="nav-link-ref" >EULA</a>
                                    <!--end icon-->
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                            <div class="row">
                                <div class="col-12" style="font-size: 0.75em;">
                                    &copy; {{year}} Stone Simple, Inc.
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->                            
                        </div>
                    </div>
                </div>
            </div>
            <!--end container-->
            <!--end footer-->
        </footer>
    </ng-template>
    <div *ngIf="footerVariant;else execute_block"></div>
</div>