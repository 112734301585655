import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-software',
  templateUrl: './index-software.component.html',
  styleUrls: ['./index-software.component.css']
})

/**
 * Software Component
 */
export class IndexSoftwareComponent implements OnInit {

  /**
   * nav Light class Add
   */
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true

  constructor(private modalService: NgbModal) { }

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "mdi mdi-pencil h1 text-primary",
      title: "Personalized Memorials",
      description: "Guide your clients through a personalized journey. Add and select customers, choose from a variety of cemeteries, and explore an array of monuments – each offering unique shapes, sizes, and colors."
    },
    {
      icon: "mdi mdi-grave-stone h1 text-primary",
      title: "Extensive Monument Collection",
      description: "Browse a comprehensive catalog of monuments, catering to various tastes and preferences. From classic designs to contemporary creations, find the perfect memorial to honor every individual."
    },
    {
      icon: "mdi mdi-layers h1 text-primary",
      title: "Intuitive Design Studio",
      description: "Step into a Photoshop-like design experience that empowers you to bring visions to life. Effortlessly add and change designs, text panels, emblems, and manage layers, while preserving the essence of your tribute."
    },
    {
      icon: "mdi mdi-adjust h1 text-primary",
      title: "Customization with Compassion",
      description: "Every detail matters. With Stone Simple, select stone colors, finishes (polished / unpolished), and refine each element until the monument perfectly captures the essence of the one being remembered."
    },
    {
      icon: "mdi mdi-emoticon-sad h1 text-primary",
      title: "Collaboration in Grief",
      description: "Serving grieving families requires compassion and collaboration. Stone Simple allows you to work closely with clients, incorporating their input to create a monument that truly reflects their loved one's legacy."
    }
  ];

  ngOnInit(): void {
  }

}
